
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface IApiLog {
  member: string;
  command: string;
  betting_id?: string;
  request: string;
  response: string;
  response_code: number;
  createdAt: string;
}
interface DateRange {
  start: string;
  end: string;
}
interface Paginated {
  rows: number;
  page: number;
  limit: number;
  data: Array<any>;
}

export default defineComponent({
  name: "api-logs",
  components: {
    Datatable,
    DateRangePicker,
    CodeHighlighter,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const route = useRoute();
    // page variables
    const tableHeader = ref([
      {
        name: t("DashboardSummary.Members"),
        key: "member",
        text: true,
        sortable: true,
      },
      {
        name: t("ApiLogs.Command"),
        key: "command",
        customslot: true,
      },
      {
        name: t("ApiLogs.Request"),
        key: "request",
        customslot: true,
      },
      {
        name: t("ApiLogs.ResponseCode"),
        key: "response_code",
        customslot: true,
      },
      {
        name: t("ApiLogs.Response"),
        key: "response",
        customslot: true,
      },
      {
        name: t("MoneyTransfer.ProcessDate"),
        key: "createdAt",
        text: true,
      },
    ]);
    const commands = [
      { value: "all", label: t("MoneyTransfer.All") },
      { value: "balance", label: t("ApiLogs.Balance") },
      { value: "debit", label: t("ApiLogs.Debit") },
      { value: "credit", label: t("ApiLogs.Credit") },
      { value: "rollback", label: t("ApiLogs.Rollback") },
    ];
    const tableData = ref<Array<IApiLog>>([]);
    let daterange = {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };
    const setSelectedDate = (date: DateRange) => (daterange = date);
    const search = ref({
      username: "",
      betId: "",
      command: "all",
    });
    /**
     * Pagination
     *
     */
    const rowsCount = ref(0);
    let pageLimit = 50;
    let pageNumber = 1;

    /**
     * Get History
     *
     */
    const getHistory = async () => {
      const results: Paginated = await ApiService.get(
        `/agent/api-logs/?status=${route.params.type}&command=${search.value.command}&username=${search.value.username}&bet_id=${search.value.betId}&start=${daterange.start}&end=${daterange.end}&limit=${pageLimit}&page=${pageNumber}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results.data);
      rowsCount.value = results.rows;
    };
    /**
     * Set Page Limit
     *
     */
    const setPageLimit = (limit: number) => {
      pageLimit = limit;
      // refresh
      getHistory();
    };
    /**
     * Set Page
     *
     */
    const setPage = (page: number) => {
      pageNumber = page;
      // refresh
      getHistory();
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(
        (route.params.type === "success"
          ? t("ApiLogs.Success")
          : t("ApiLogs.Error")) +
          " " +
          t("Menu.ApiLogs"),
        []
      );
      // get history
      getHistory();
    });
    return {
      tableData,
      tableHeader,
      commands,
      setSelectedDate,
      setPageLimit,
      setPage,
      getHistory,
      search,
      rowsCount,
    };
  },
});
